import React, { useEffect, useRef, useState } from 'react';
import Topbar from '../../layout/topbar';
import ContainerComponent from '../../components/container/ContainerComponent';
import { Button } from 'primereact/button';
import Footer from '../../layout/footer';
import api from '../../services/api';
import Constants from '../../services/constants';
import { Toast } from 'primereact/toast';
import { CustomInputText, CustomRadioButtons } from '../../components/input/input';
import { useSearchParams } from 'react-router-dom';
import car from '../../assets/images/car.svg';
import { Dialog } from 'primereact/dialog';
import success from '../../assets/images/7efs.gif';
import { RadioButton } from 'primereact/radiobutton';

export default function Home() {
    const toast = useRef();
    const [vehicleDetail, setVehicleDeatil] = useState({});
    const [emergencyAlerts, setEmergencyAlerts] = useState([]);
    const [searchParams] = useSearchParams();
    const getAllAlerts = async () => {
        const qr = searchParams.get('qr');
        if (qr) {
            const res = await api('post', Constants.END_POINT.QR_DETAIL, { qr });
            if (res.success) {
                if (res.data) {
                    setVehicleDeatil(res.data);
                    setErr((prev) => ({ ...prev, vehicleDetail: '' }));
                }
            } else {
                setErr((prev) => ({ ...prev, vehicleDetail: 'Please Try Again' }));
            }
        }
    };

    useEffect(() => {
        getAllAlerts();
        getAllEmergencyAlerts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [data, setData] = useState({
        emergencyAlert: '',
        otherUserMobile: '',
    });
    const [loading, setLoading] = useState(false);
    const [sucessMsg, setSuccessMsg] = useState('');

    const getAllEmergencyAlerts = async () => {
        const res = await api('get', Constants.END_POINT.EMERGENCY_ALERTS);
        if (res.success) {
            if (res.data) {
                setEmergencyAlerts(res.data);
            }
        }
    };
    const [err, setErr] = useState({});

    const validateForm = () => {
        let errors = {};
        if (!data?.emergencyAlert) {
            errors.emergencyAlert = 'Please Select the reason';
        }
        setErr(errors);
        if (Object.keys(errors).length === 0) {
            return true;
        } else {
            return false;
        }
    };
    const userSendAlert = async () => {
        const form = validateForm();
        if (form) {
            setLoading(true);
            const payload = {
                alertUser: vehicleDetail?.user?._id,
                emergencyAlert: data?.emergencyAlert?._id,
                vehicle: vehicleDetail?._id,
                otherUserMobile: data.otherUserMobile,
            };
            const res = await api('post', Constants.END_POINT.USER_ALERT, payload);
            if (res.success) {
                if (res.data) {
                    setLoading(false);
                    setSuccessMsg(res.message);
                }
            } else {
                setLoading(false);
                toast.current.show({ severity: 'error', summary: res.message });
            }
        }
    };

    const handleChange = ({ name, value }) => {
        setData((prev) => ({ ...prev, [name]: value }));
        setErr((prev) => ({ ...prev, [name]: '' }));
    };

    const handleSendAlert = () => {
        userSendAlert();
    };

    console.log('sucessMsg>>', sucessMsg);

    return (
        <>
            <Topbar />
            <Toast style={{ zIndex: '9999999999999' }} ref={toast} />

            {err?.vehicleDetail ? (
                <div className="p-error text-lg text-left m-auto cstmError">
                    <img src={car} width={150} alt="" />
                    <h3 className="text-lg font-semibold">Vehicle Detail Not Found.</h3>
                    {err?.vehicleDetail}
                </div>
            ) : (
                <ContainerComponent>
                    <div className="py-3">
                        <h5 className="h2 text-dark fw-semibold m-0 mb-2">Contact Vehicle Owner.</h5>
                        <p className="text-red-color h5 fw-medium m-0 mb-2">
                            {vehicleDetail?.vehicleBrand?.name} ({vehicleDetail?.vehicleModel?.name})
                        </p>
                        <p className="text-dark h6 fw-semibold m-0 mb-2">{vehicleDetail?.vehicleNumber}</p>
                        <small>Please select a reason why do you want to contact the owner.</small>
                        <div className="mt-2 w-full md:w-4">
                            <CustomRadioButtons
                                name="emergencyAlert"
                                onChange={handleChange}
                                data={data}
                                options={emergencyAlerts}
                                errorMessage={err?.emergencyAlert}
                            >
                                {emergencyAlerts.map((option) => (
                                    <RadioButton
                                        inputId={option.value}
                                        name="emergencyAlert"
                                        value={option}
                                        onChange={(e) => handleChange({ name: 'emergencyAlert', value: e.value })}
                                        checked={data.emergencyAlert?._id === option._id}
                                    />
                                ))}
                            </CustomRadioButtons>
                            <div className="mb-3 ">
                                <p htmlFor="otherUserMobile" className="small mb-2">
                                    Do you want the Owner to call you ?
                                </p>
                                <CustomInputText
                                    inputId="otherUserMobile"
                                    className="mobile-input mt-2"
                                    name="otherUserMobile"
                                    placeholder="Mobile number (optional)"
                                    onChange={handleChange}
                                    data={data}
                                    keyfilter="int"
                                />
                            </div>
                            <Button label="Send Alert" className="send-btn btn text-white p-2 bg-yellow main-btn" onClick={handleSendAlert} loading={loading} />
                        </div>
                    </div>
                </ContainerComponent>
            )}
            <Dialog
                header=""
                contentClassName="cstmcontent"
                headerClassName="dialogHeader"
                visible={sucessMsg}
                className="cstmdialog"
                onHide={() => {
                    if (!sucessMsg) return;
                    setSuccessMsg('');
                    setData({
                        emergencyAlert: '',
                        otherUserMobile: '',
                    });
                }}
            >
                <img src={success} className="successIcon" alt="" />
                <p className="m-0">{sucessMsg}</p>
            </Dialog>
            <Footer />
        </>
    );
}
