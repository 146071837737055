import React from 'react';
import TollImg from '../assets/icons/Scan me final new-02.png';
import CallImg from '../assets/icons/Scan me final new-03.png';
import WhatsappImg from '../assets/icons/Scan me final new-05.png';
import SmsImg from '../assets/icons/Scan me final new-04.png';
import AppStoreImg from '../assets/images/image 1.png';
import GooglePlayImg from '../assets/images/image 2.png';
import ContainerComponent from '../components/container/ContainerComponent';
import { ANDROID_APP_LINK, IOS_APP_LINK } from '../constants';

export default function Footer() {
    return (
        <>
            <div className="py-4">
                <ContainerComponent>
                    <div className="footer-box md:flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center gap-3 justify-content-center mb-4 md:m-0">
                            <img src={TollImg} alt="" width={45} />
                            <img src={CallImg} alt="" width={45} />
                            <img src={WhatsappImg} alt="" width={45} />
                            <img src={SmsImg} alt="" width={45} />
                        </div>
                        <div className="d-flex justify-content-center md:justify-content-end gap-3">
                            <a href={IOS_APP_LINK || "#"} target="_blank" rel="noopener noreferrer">
                                <img src={AppStoreImg} alt="Download on App Store" width={147} />
                            </a>
                            <a href={ANDROID_APP_LINK || "#"} target="_blank" rel="noopener noreferrer">
                                <img src={GooglePlayImg} alt="Get it on Google Play" width={147} />
                            </a>
                        </div>
                    </div>
                </ContainerComponent>
            </div>
        </>
    );
}
