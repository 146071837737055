import React from 'react'
import ContainerComponent from '../components/container/ContainerComponent';
import LogoImg from "../assets/images/logo.png";

export default function Topbar() {
  return (
    <div className='py-3 border-bottom border-200'>
        <ContainerComponent>
            <img src={LogoImg} alt='' width={150} />
        </ContainerComponent>
    </div>
  )
}
