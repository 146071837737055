export const isAuthenticated = () => {
    if (typeof window === 'undefined') {
        return false;
    }
    if (localStorage.getItem('token')) {
        return JSON.parse(localStorage.getItem('token'));
    } else {
        return false;
    }
};
export const authenticate = (token, next) => {
    if (typeof window !== 'undefined') {
        localStorage.setItem('token', JSON.stringify(token));
        next();
    }
};

export const logout = (next) => {
    if (typeof window !== 'undefined') {
        localStorage.clear();
        next();
    }
};
