import PortfolioImg from "../../assets/img/1.png";
import PortfolioImg1 from "../../assets/img/2.png";
import PortfolioImg2 from "../../assets/img/3.png";
import PortfolioImg3 from "../../assets/img/4.png";

const HomepageData = () => {
  const ServiceContent = [
    {
      img: PortfolioImg,
    },
    {
      img: PortfolioImg1,
    },
    {
      img: PortfolioImg2,
    },
    {
      img: PortfolioImg3,
    },
  ];

  return { ServiceContent };
};
export default HomepageData;
