export const types = {
    SHOW_TOAST: 'SHOW_TOAST',
    SHOW_LOADER: 'SHOW_LOADER',
    HIDE_LOADER: 'HIDE_LOADER',
    SHOW_SIDEBAR_LOADER: 'SHOW_SIDEBAR_LOADER',
    HIDE_SIDEBAR_LOADER: 'HIDE_SIDEBAR_LOADER',
    UPDATE_UPLOAD_PROGRESS: 'UPDATE_UPLOAD_PROGRESS',
    USER_DETAIL: 'USER_DETAIL',

    GET_VEHICLES: 'GET_VEHICLES',
    PROFILE: 'PROFILE',
};
