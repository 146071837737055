export default class Constants {
    static END_POINT = {
        LOGIN: '/user/login',
        PROFILE: '/user/profile/',
        REQUEST_OTP: '/user/request-otp',

        GET_VEHICLES: '/user/vehicle',

        QR_DETAIL: '/qr-details',
        EMERGENCY_ALERTS: '/emergency-alert',
        USER_ALERT: '/user-alert',
        VEHICLE_PLAN: '/user/plan-web/',
        DOWNLOAD_INVOICE: '/user/plan/invoice/',
        CANCEL_SUBSCRIPTION: '/user/plan/cancel-subscription/',
        UPGRADE_SUBSCRIPTION: '/user/plan/upgrade-subscription/',
        AFTER_SUBSCRIPTION: '/user/plan/after-subscription/',
        BUY_SUBSCRIPTION: '/user/plan/subscribe/',
    };
}
