import React from "react";
import { useNavigate } from "react-router-dom";

export default function CustomCard({ title, children, className, backable = true, ...props }) {
    const navigate = useNavigate();
    return (
        <div className={`label-table ${className}`}>
            <div className={`card p-2   ${className}`}>
                {title ? (
                    <div className="surface-300 py-3 px-3 flex justify-content-start w-full m-0">
                        {backable ? <i className="pi pi-angle-left text-4xl my-auto cursor-pointer" onClick={() => navigate(1)} /> : null}
                        <div className="text-2xl my-auto">{title}</div>
                    </div>
                ) : null}
                <div className="p-4   p-fluid">{children}</div>
            </div>
        </div>
    );
}
export const DashBoardCard = ({ title, subTitle, count, onClick, iconClassName = "pi pi-users" }) => {
    return (
        <>
            <div className="col-12 lg:col-6 xl:col-3 cursor-pointer light " onClick={onClick}>
                <div className="card p-3 bg-white mb-0">
                    <div className="flex justify-content-between mb-3">
                        <div>
                            <span className="block text-500 font-medium mb-3">{title}</span>
                            <div className="text-900 font-medium text-xl">{count}</div>
                        </div>
                        <div
                            className="flex align-items-center justify-content-center bg-cyan-100 border-round"
                            style={{ width: "2.5rem", height: "2.5rem" }}
                        >
                            <i className={`${iconClassName} text-cyan-500 text-xl`} />
                        </div>
                    </div>
                    <span className="text-500">{subTitle}</span>
                </div>
            </div>
        </>
    );
};
