import React from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { PrivateRoutes } from './allRoutes';
import PageNotFound from '../views/PageNotFound';
import AuthNavbar from '../components/AuthNavbar';
import { isAuthenticated } from '../services/auth';

const Auth = () => {
    return isAuthenticated() ? (
        <>
            <AuthNavbar />
            <Outlet />;
        </>
    ) : (
        <Navigate to="/404" />
    );
};

export const ProtectedRoutes = () => {
    return (
        <Routes>
            <Route element={<Auth />}>
                {PrivateRoutes.map((route, key) => (
                    <Route key={key} path={route.path} element={route.element} />
                ))}
            </Route>
            <Route path="*" element={<PageNotFound />} />
        </Routes>
    );
};
