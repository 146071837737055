import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { authenticate, isAuthenticated } from '../services/auth';
import { jwtDecode } from 'jwt-decode';

const TokenLogin = () => {
    const { token, vehicleID } = useParams();
    const navigate = useNavigate();

    const isValidJwt = (token) => {
        try {
            const parts = token.split('.');
            if (parts.length !== 3) return false;

            const decoded = jwtDecode(token);

            if (!decoded?.userId || !decoded?.exp) return false;

            const currentTime = Math.floor(Date.now() / 1000);
            return decoded.exp > currentTime;
        } catch (error) {
            return false;
        }
    };

    useEffect(() => {
        if (token && isValidJwt(token)) {
            authenticate(token, () => {
                if (isAuthenticated()) {
                    navigate(`/dashboard/${vehicleID}`);
                }
            });
        } else {
            navigate('/');
        }
    }, [token]);
    return <div>Rediercting.... {token}</div>;
};

export default TokenLogin;
